import React from "react"
import Slider from "react-slick"
import Img from "gatsby-image"
import RichText from "./richtext"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Arrow = ({ className }) => (
  <svg
    width="31"
    height="24"
    viewBox="0 0 31 24"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.7473 12.75H0V11.25H27.7473L18.4575 1.51789L19.5425 0.482178L30.0425 11.4822L30.5368 12L30.0425 12.5179L19.5425 23.5179L18.4575 22.4822L27.7473 12.75Z"
      fill="black"
    />
  </svg>
)

const NextArrow = ({ className, onClick, style }) => (
  <div
    className={`absolute right-0 top-1/2 cursor-pointer z-10`}
    onClick={onClick}
  >
    <Arrow />
  </div>
)

const PrevArrow = ({ className, onClick, style }) => (
  <div
    className={`absolute left-0 top-1/2 cursor-pointer z-10`}
    onClick={onClick}
  >
    <Arrow className="transform rotate-180" />
  </div>
)

const Slideshow = ({ items }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  return (
    <div className="max-w-6xl mx-auto px-4">
      <Slider {...settings}>
        {items.map(({ slide_image, caption, caption_richtext }, i) => (
          <div key={i}>
            {slide_image && (
              <Img
                fluid={slide_image.fluid}
                className="max-w-6xl mx-auto max-h-80vh mb-8 px-12"
                imgStyle={{ objectFit: "contain" }}
              />
            )}
            {caption_richtext && <RichText html={caption_richtext.html} />}
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Slideshow
