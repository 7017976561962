import React from "react"
import Img from "gatsby-image"

import RichText from "./richtext"
import MapText from "./map-text"
import Slideshow from "./slideshow"

const Slice = ({ primary, items, slice_type }) => (
  <>
    {slice_type === "richtext" && (
      <div className="max-w-3xl mx-auto w-full mb-10 px-4">
        <RichText html={primary.richtext.html} />
      </div>
    )}
    {slice_type === "image" && (
      <Img
        fluid={primary.image.fluid}
        className="max-w-6xl mx-auto max-h-screen"
        imgStyle={{ objectFit: "contain" }}
      />
    )}
    {slice_type === "map___text" && <MapText {...primary} />}
    {slice_type === "slideshow" && <Slideshow items={items} />}
  </>
)

export default Slice
