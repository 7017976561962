import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import RichText from "../components/richtext"
import Slice from "../components/slice"

import { formatDate } from "../utils/data"

const ExhibitTemplate = ({ data }) => {
  const {
    name,
    subtitle,
    start_date,
    end_date,
    image,
    description,
    short_description,
    body,
  } = data.prismicExhibit.data
  return (
    <Layout>
      <div className="max-w-6xl mx-auto w-full px-4 py-10 flex flex-col md:flex-row-reverse">
        <div className="md:w-1/2 md:pl-4">
          <div className="pb-8">
            <h1>{name.text}</h1>
            {subtitle && <h3>{subtitle}</h3>}
          </div>
          <p>
            {formatDate(start_date)} - {formatDate(end_date)}
          </p>
          {description.html ? (
            <RichText html={description.html} />
          ) : short_description ? (
            <p>{short_description}</p>
          ) : (
            <></>
          )}
        </div>
        <div className="w-full md:w-1/2">
          <Img fluid={{ ...image.fluid }} className="w-full flex-1" />
        </div>
      </div>
      {body.map((slice, i) => (
        <Slice {...slice} key={i} />
      ))}
    </Layout>
  )
}

export default ExhibitTemplate

export const query = graphql`
  query ExhibitByUid($uid: String!) {
    prismicExhibit(uid: { eq: $uid }) {
      data {
        name {
          text
        }
        subtitle
        start_date
        end_date
        image {
          fluid(maxWidth: 1024) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        description {
          html
        }
        short_description
        body {
          ... on PrismicExhibitBodyRichtext {
            slice_type
            primary {
              richtext {
                html
              }
            }
          }
          ... on PrismicExhibitBodySlideshow {
            slice_type
            items {
              slide_image {
                fluid(maxWidth: 1024) {
                  ...GatsbyPrismicImageFluid
                }
              }
              caption_richtext {
                html
              }
            }
          }
        }
      }
    }
  }
`
