import React from "react"

import Map from "./map"
import RichText from "./richtext"

const MapText = ({ text }) => (
  <div className="bg-white">
    <div className="my-10 flex flex-col md:flex-row-reverse justify-center mx-4">
      <div className="md:flex-1">
        <div className="md:max-w-lg md:mr-auto md:px-6 py-6">
          <div className="mb-8">
            <RichText html={text.html} />
          </div>
        </div>
      </div>
      <div className="md:flex-1 md:w-1/2 pt-8">
        <div className="mx-4 overflow-hidden">
          <Map />
        </div>
      </div>
    </div>
  </div>
)

export default MapText
